.mobile-nav {
  display: none;
  background-image: linear-gradient(
    to right,
    rgb(73, 113, 245),
    rgb(143, 143, 143)
  );
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 1.3em;
  box-shadow: rgba(207, 207, 207, 0.224) 1px 1px 1px 2px;
  overflow: hidden;
  position: sticky;
  top: 0;
  max-width: 100vw;
  height: 10vh;
  grid-column: 1 / 4;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  gap: 10px;
  z-index: 10;
}

.mobile-nav a {
  color: white;
  text-decoration: none;
}

.nav-icon {
  font-size: 1.4em;
}

.mobile-nav {
  position: relative;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1200px) {
  .mobile-nav {
    display: grid;
    position: sticky;
  }

  .mobile-nav.hidden .nav-links {
    transform: translateX(5000px);
  }

  .mobile-nav .nav-links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    transform: translateX(0);
    color: white;
    transition: transform 0.25s ease-in-out;
    grid-column: 1 / 12;
    overflow: hidden;
  }
  .show-menu {
    font-size: 1.2em;
    grid-column: 12 / 13;
    cursor: pointer;
  }
}
