.projects-container {
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  transform: translateX(-150px);
  transition: transform 0.5s;
  padding-bottom: 50px;
}

.projects-container .section-title {
  align-self: center;
}

.light .projects-container {
  transform: translateX(0);
}

.project-grid {
  display: grid;
  grid-template-rows: minmax(300px, 350px);
  gap: 100px;
  padding-top: 50px;
}

@media screen and (max-width: 1200px) {
  .projects-container {
    transform: translateX(0);
    right: 0;
    gap: 70px;
  }

  .project-grid {
    grid-template-rows: minmax(150px, 400px);
    padding: 30px;
  }
}
