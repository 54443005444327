:root {
  --custom-height: 700px;
}

.spinner-container {
  position: absolute;
  left: -350px;
  min-height: var(--custom-height);
  min-width: var(--custom-height);
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: 350px;
  transform: rotate(0deg) translateX(-250px);
  transition: 0.5s;
  font-family: "Roboto", sans-serif;
}

.sun {
  position: absolute;
  list-style: none;
  cursor: pointer;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
  height: var(--custom-height);
  width: var(--custom-height);
  border-radius: 50%;
  z-index: 10000;
  background-image: linear-gradient(
    to right,
    rgb(248, 244, 12),
    rgb(225, 102, 20)
  );
  background-color: rgb(248, 244, 12);
  transition: box-shadow 1.5s;
  border: solid rgb(225, 102, 20) 2px;
}

.links {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.spinner-item {
  position: absolute;
  list-style: none;
  transform-origin: 410px;
  left: 0;
  transform: rotate(0deg);
  transition-property: transform, background-color;
  transition-duration: 0.5s, 0s;
  transition-delay: calc(0.1s * var(--i)), 0s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: rgb(248, 193, 12);
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0px 2px 10px 5px rgba(233, 211, 9, 0.321);
}

.spinner-item:hover {
  background-color: rgb(252, 174, 7);
}

.spinner-container.active {
  transform: translateX(-100px);
}

.spinner-container:hover .spinner-title {
  color: black;
}

.spinner-container.active .sun {
  box-shadow: 30px 5px 10px 10px rgba(227, 221, 167, 0.321);
  background-image: linear-gradient(
    to right,
    rgb(248, 244, 12),
    rgb(252, 174, 7) 90%
  );
  border-color: rgb(252, 174, 7);
}

.spinner-container.active .spinner-item {
  transform: rotate(calc(180deg / 6 * var(--i) + 105deg));
}

.menu-icon {
  width: 30px;
  height: 30px;
  color: rgb(5, 137, 238);
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: 15px;
  transform: rotate(calc(-180deg / 6 * var(--i) - 105deg));
}

@keyframes rotateslowly {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner-title {
  position: absolute;
  right: -20px;
  z-index: 10001;
  list-style: none;
  transform: rotateZ(90deg);
  font-size: 4em;
  cursor: pointer;
  transition: color 0.5s;
  color: white;
  font-family: "Nunito", sans-serif;
}

.spinner-container.active .spinner-title {
  display: none;
}

.menu-label {
  position: absolute;
  right: 10px;
  text-align: center;
  bottom: 50%;
  font-size: 4em;
  color: transparent;
  font-family: "Nunito", sans-serif;
  font-weight: bolder;
}

.spinner-container.active:hover .menu-label {
  color: rgb(26, 136, 255);
  transition: color 0.5s;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .desktop-menu {
    display: none;
  }
}
