.about-container {
  min-height: 100vh;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 50px;
  row-gap: 50px;
  position: relative;
  transform: translateX(-150px);
  padding-bottom: 50px;
  padding-right: 50px;
  transition: transform 0.5s;
  margin-bottom: 5px;
  overflow: auto;
}

.light .about-container {
  transform: translateX(0);
}
.avatar-wrapper {
  grid-column: 1/3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: 10em;
  min-width: 250px;
  border-radius: 50%;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.274);
}

.about-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Nunito", sans-serif;
  font-size: 1.7em;
  height: 100%;
  text-align: center;
  gap: 50px;
}

.about-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Nunito", sans-serif;
  font-size: 1.7em;
  color: white;
  gap: 50px;
}
.tech-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  font-family: "Roboto", sans-serif;
}

.introduction {
  color: white;
}

.tech-icon {
  font-size: 3em;
}

.about-right li {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.word-highlight {
  color: rgb(9, 203, 9);
}

.light .word-highlight {
  color: rgb(252, 252, 5);
}

.see-projects {
  outline: none;
  border: none;
  padding: 30px;
  border-radius: 15px;
  border: solid rgb(225, 102, 20) 3px;
  background-color: transparent;
  cursor: pointer;
  color: white;
  margin-top: 20px;
  transition: border-color 0.8s;
  display: flex;
  gap: 10px;
  font-size: 2em;
}

.light .see-projects {
  border-color: rgb(131, 0, 164);
}

.light .see-projects:hover > .down-arrow {
  color: rgb(131, 0, 164);
}

.see-projects:hover > .down-arrow {
  color: rgb(225, 102, 20);
}

@media screen and (max-width: 1200px) {
  .about-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(0);
    font-size: 1.5em;
    padding-right: 0;
  }
  .about-container .section-title {
    align-self: center;
  }

  .avatar {
    width: 7em;
    margin-bottom: 30px;
  }
  .about-left {
    font-size: 0.8em;
    max-width: 100vw;
    padding: 30px;
  }
  .tech-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 0.6em;
  }
  .see-projects {
    outline: none;
    border: none;
    padding: 10px;
    font-size: 0.7em;
    border-radius: 15px;
    border: solid rgb(225, 102, 20) 3px;
    background-color: transparent;
    cursor: pointer;
    color: white;
    margin-top: 20px;
    transition: border-color 0.8s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}
