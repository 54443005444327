.project-card {
  max-height: 350px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  font-family: "Nunito", sans-serif;
  color: white;
  background-color: transparent;
  border-radius: 10px;
  text-align: center;
}
.project-card:nth-child(even) {
  flex-direction: row-reverse;
}

.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  font-size: 1.5em;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.hover-zoom {
  height: 300px;
}

.hover-zoom:hover {
  transform: scale(1.2);
}

.project-button {
  outline: none;
  border: none;
  padding: 10px;
  font-size: 0.7em;
  border-radius: 15px;
  border: solid rgb(225, 102, 20) 3px;
  background-color: transparent;
  cursor: pointer;
  color: white;
  transition: border-color 0.8s;
  display: flex;
  gap: 10px;
  font-size: 1.2em;
}

.project-button a {
  text-decoration: none;
  color: white;
}

.light .project-button {
  border-color: rgb(131, 0, 164);
}

@media screen and (max-width: 1200px) {
  .project-card {
    max-width: 100vw;
    max-height: fit-content;
    flex-direction: column;
    gap: 30px;
    text-align: center;
  }

  .project-card:nth-child(even) {
    flex-direction: column;
  }

  .hover-zoom {
    max-height: 150px;
  }
  .hover-zoom:hover {
    transform: scale(0);
  }
}
