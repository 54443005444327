@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito:wght@200&family=Roboto:ital,wght@0,100;0,300;0,400;1,100&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.page-container {
  min-width: 100vw;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-image: linear-gradient(
    to right,
    rgb(71, 142, 153),
    rgb(34, 64, 64),
    black
  );
}

.main-content {
  grid-column: 2 / 4;
  height: fit-content;
  display: grid;
  grid-template-rows: repeat(auto, fit-content);
  justify-content: center;
  align-items: center;
}

.section-title {
  grid-column: 1 / 3;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 4em;
  color: white;
  align-self: baseline;
}

.light {
  background-image: linear-gradient(
    to right,
    rgb(89, 175, 189),
    rgb(106, 203, 218)
  );
}

nav {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
}

@media screen and (max-width: 1200px) {
  .page-container {
    width: 100vw;
    position: absolute;
  }
  .nav {
    height: 10vh;
  }
  .main-content {
    width: 100vw;
    position: relative;
    grid-column: 1 / 4;
  }
  .section-title {
    font-size: 3em;
  }
}
